<template>
    <Toast />     
    <div class="card">
        <div v-if="can('reglas.columnas.store')">
          <Toolbar class="mb-4">
            <template #end>
              <Button label="Nueva" icon="pi pi-plus" severity="success" class="mr-2" @click="openNew" />
            </template>          
          </Toolbar>
        </div>
        <DataTable v-model:filters="filters" :value="columns" dataKey="id" 
        filterDisplay="row" stripedRows tableStyle="min-width: 50rem" >
            <template #empty>  No existen registros. </template>
            <template #loading> cargando datos de reglas. Por favor espere. </template>
            <Column field="tablero" header="Tablero" style="min-width: 12rem">
              <template #body="{ data }">
                {{ data.tablero }}
              </template>
              <template #filter>
                <InputText id="filterTablero" v-model="filterTablero" type="text" @keydown.enter="getColumnas()" placeholder="Buscar por tablero" />
              </template>
            </Column>
            <Column field="columna" header="Columna" style="min-width: 12rem">
              <template #body="{ data }">
                {{ data.nombre }}
              </template>
              <template #filter>
                <InputText id="filterColumna" v-model="filterColumna" type="text" @keydown.enter="getColumnas()" placeholder="Buscar por columna" />
              </template>
            </Column>
            <Column :exportable="false" header="Opciones" style="min-width:8rem">
              <template #body="slotProps">
                <Button v-if="can('reglas.columnas.show')" icon="pi pi-eye" outlined rounded @click="show(slotProps.data)" />
                <Button v-if="can('reglas.columnas.update')" icon="pi pi-pencil" outlined rounded severity="success" @click="edit(slotProps.data)" />
                <Button v-if="can('reglas.columnas.delete')" icon="pi pi-trash" outlined rounded severity="danger" @click="confirmDelete(slotProps.data)" /> 
              </template>
            </Column> 
        </DataTable>
        <Paginator :rows="10" :totalRecords="totalItems" @page="onPage($event)"></Paginator>
    </div>
    <Dialog v-model:visible="formDialog" :style="{width: '450px'}" header="" :modal="true" class="p-fluid" position="top">
      <div class="field">
        <label for="columna" class="mb-3">Tablero/Columna</label>
        <TreeSelect v-model="model.columna" :options="nodes" placeholder="Seleccione una columna" class="md:w-20rem w-full" parent />
        <small class="p-error" v-if="submitted && !model.columna">La columna es requerida.</small>
      </div>
      <div class="field">
        <label for="checklist" class="mb-3">Checklists</label>
        <MultiSelect v-model="model.checklist" :options="checklists" filter optionLabel="nombre" optionValue="id" placeholder="Seleccione" class="w-full md:w-20rem" />
        <small class="p-error" v-if="submitted && !model.checklist">Seleccione al menos un checklist.</small>
      </div>      
      <div class="field">            
        <label for="categoria" class="mb-3">Categoría</label>
        <MultiSelect 
          v-model="model.categoria_nombre" 
          :options="categorias" 
          optionLabel="nombre"
          optionValue="nombre" 
          :maxSelectedLabels="3"
          class="w-full md:w-20rem"
          @change="getRamos"
        />
        <small class="p-error" v-if="submitted && !model.categorias">Seleccione al menos una categoría.</small>
      </div>
      <div class="field">            
        <label for="ramo" class="mb-3">Ramo</label>
        <MultiSelect 
          v-model="model.ramo_id" 
          :options="ramos" 
          optionLabel="nombre"
          optionValue="id" 
          :maxSelectedLabels="3"
          class="w-full md:w-20rem"
          @change="getSubramos"
        />
        <small class="p-error" v-if="submitted && !model.ramo_id">Seleccione al menos un ramo.</small>
      </div>
      <div class="field">            
        <label for="ramo" class="mb-3">Subramo</label>
        <MultiSelect 
          v-model="model.subramo_id" 
          :options="subramos" 
          optionLabel="nombre"
          optionValue="id" 
          :maxSelectedLabels="3"
          class="w-full md:w-20rem"
          @change="getIdcategoria"
        />
        <small class="p-error" v-if="submitted && !model.ramo_id">Seleccione al menos un subramo.</small>
      </div>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" text @click="hideDialog"/>
        <Button label="Guardar" icon="pi pi-check" text @click="save" />
      </template>
      
    </Dialog>
    <Dialog v-model:visible="showDialog" style="width: 33rem"  :modal="true" position="top">
      <Card style="width: 30rem; overflow: hidden">
        <template #title>Tableo/Columna: {{ this.showcolumna }} </template>
        <template #content>                    
          <div class="field">
            <label for="items" class="mb-3">Checklist: </label>            
            <li v-for="(item, index) in this.showchecklists" :key="index">
                {{ item.nombre }}
            </li>
          </div>
          <div class="field">
            <label for="items" class="mb-3">Categorias: </label>            
            <li v-for="(item, index) in this.showcategorias" :key="index">
                {{ item.nombre }}
            </li>
          </div>
        </template>
      </Card>      
    </Dialog>
    <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}"  :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="model">Realmente desea eliminar las reglas para la columna <b>{{model.columna}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" text @click="deleteDialog = false"/>
            <Button label="Si" icon="pi pi-check" text @click="destroy" />
        </template>
    </Dialog>
</template>

<script>

import { sendRequestList, userCan, sendRequest} from '@/helper';
import { FilterMatchMode } from 'primevue/api';

const url = process.env.VUE_APP_BASE_URL;
export default {
  data() {
    return {
      nodes: null,
      checklists: null,
      selectedValue: null,
      checklist: null,
      categorias: null,
      filters: {            
          name: { value: null, matchMode: FilterMatchMode.CONTAINS },            
      },
      columns: null,
      loading: false,          
      totalItems: 0,
      filterTablero: "",
      filterColumna: "",
      params: "",
      formDialog: false,
      showDialog: false, 
      submitted: false,
      model: {},
      showchecklists: [],
      showcategorias: [],
      showcolumna: "",
      reglas: null,
      deleteDialog: false,
      ramos: null,
      subramos: null
    }
  },
  methods: {
    can(action) {
      return userCan(action);
    },
    async getTablerosColumnas() {
      const result = await sendRequestList(url+"/listtableroscolumnas");
      this.nodes = result.data.data;
    },
    async getCheckLists() {
      const result = await sendRequestList(url+"/checklist");
      this.checklists = result.data.data;
    },
    async getCategorias() {
      const result = await sendRequestList(url+"/categoriaselect");
      this.categorias = result.data;
    },
    async getRamos() {
      this.model.ramo_id = null;
      this.model.subramo_id = null;
      this.model.categoria_id = null;
      const result = await sendRequestList(url+"/ramosselect?nombre="+this.model.categoria_nombre);
      this.ramos = result.data.data;
    },
    async getSubramos() {
      this.model.subramo_id = null;
      this.model.categoria_id = null;
      const result = await sendRequestList(url+"/subramosselect?ramo="+this.model.ramo_id+"&nombre="+this.model.categoria_nombre);
      this.subramos = result.data.data;
    },
    async getIdcategoria() {
      const result = await sendRequestList(url+"/categoriabynombre?subramo="+this.model.subramo_id+"&nombre="+this.model.categoria_nombre);
      const categorias = result.data.data;
      if (categorias) {
          this.model.categorias = categorias.map(obj => obj.id);
      }
    },
    async getColumnas() {
      this.loading = true;
      this.makeParams();
      const result = await sendRequestList(url+"/indexreglacolumna?page=1"+this.params);
      this.columns = result.data.data;
      this.loading = false;
      this.totalItems = result.data.meta.total; 
    },

    makeParams(){          
      this.params= "";          
      this.params = this.addParam(this.params, "columna", this.filterColumna);
      this.params = this.addParam(this.params, "tablero", this.filterTablero);
    },
    addParam(params, param="", value=""){
      const valueEndode = encodeURIComponent(value);          
      params = (value != null && value !== "")? params+"&"+param+"="+valueEndode : params;
      return params;
    }, 
    async onPage(event) {
      this.loading = true;
      var page = event.page + 1;
      this.makeParams();
      const result = await sendRequestList(url+"/indexreglacolumna?page="+page+this.params);
      this.columns = result.data.data;
      this.loading = false;          
    },
    openNew() {
      this.getTablerosColumnas();
      this.getCheckLists();
      this.getCategorias();
      this.model = {};
      this.submitted = false;
      this.formDialog = true;
    },
    hideDialog() {
      this.submitted = false;
      this.formDialog = false;
    },
    async save() {        
      this.submitted = true;
      var message = "";
      var result = "";
      if(this.model.columna && this.model.checklist && this.model.categorias) {
        var params = {
          columna: Object.keys(this.model.columna),
          checklists: this.model.checklist,
          categorias: this.model.categorias
        };      

        if(this.model.column_id) {
          message = "Regla actualizada";
          result = await sendRequest(
            "PUT",
            params,
            url+"/updatereglacolumna/"+this.model.column_id
          ); 
        } else {
          message = "Regla registrada";
          result = await sendRequest(
            "POST",
            params,
            url+"/storereglacolumna"
          );
        }
        

        if (result === "ok") {
          this.$toast.add({severity:'success', summary: 'Éxito', detail: message, life: 3000});
          this.formDialog = false;  
          this.model = {};
          this.getColumnas();
        } else {
          this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
        }          
      }
    },
    async show(data) {      
      await this.getReglas(data.id);      
      this.showchecklists= this.reglas.checklists;  
      this.showcategorias= this.reglas.categorias;  
      this.showcolumna = this.reglas.columna;         
      this.showDialog = true;
    },
    async edit(data) {
      await this.getReglas(data.id);
      this.getTablerosColumnas();
      this.getCheckLists();
      await this.getCategorias();
            
      this.model = {
        column_id: data.id, 
        columna: this.reglas.columna_key,
        checklist: this.reglas.checklist_ids,
        categorias: this.reglas.categorias,
        categoria_nombre: Object.values(this.reglas.categoria_nombre),
        ramo_id: Object.values(this.reglas.ramo_id),
        subramo_id: Object.values(this.reglas.subramo_id),
      };

      const result_ramos = await sendRequestList(url+"/ramosselect?nombre="+this.model.categoria_nombre);
      this.ramos = result_ramos.data.data;

      const result_subramo = await sendRequestList(url+"/subramosselect?ramo="+this.model.ramo_id+"&nombre="+this.model.categoria_nombre);
      this.subramos = result_subramo.data.data;

      this.submitted = false;
      this.formDialog = true;
    },
    async getReglas(columna_id) {
      const result = await sendRequestList(url+"/showreglacolumna/"+columna_id);
      this.reglas = result.data;
    },
    confirmDelete(data) {
      this.model.column_id = data.id;
      this.model.columna = data.nombre;
      this.deleteDialog = true;
    },
    async destroy() {
      const id = this.model.column_id;
      const result = await sendRequest('DELETE', {id: id},url+"/reglacolumna/"+id); 
      
      if (result === "ok") {
        this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Reglas eliminadas', life: 3000});
      } else {
        this.$toast.add({severity:'error', summary: 'Error', detail: result, life: 3000});
      }
      this.model = {};
      this.deleteDialog = false;
      this.getColumnas();
    }

  },
  mounted() {
      this.getColumnas();
  }
}
</script>
<style>
.p-column-filter-menu-button{
  visibility:hidden;
}
</style>